import React from "react";
import styled from "styled-components";

import Button from "../../Layout/ButtonDefault/Button";
import Tooltip from "../../Layout/Tooltip/Tooltip";
import {
	LeftIcon,
	RightIcon,
	DoubleLeftIcon,
	DoubleRightIcon,
} from "../Icon/Icon";

const PaginationButton = styled(Button)`
	text-align: center;
	line-height: 50%;
	box-shadow: none;
	display: flex;
	position: relative;
	justify-content: center;

	&:hover ${Tooltip} {
		visibility: visible;
	}
`;

const Pagination = styled.div`
	font-family: Jost;
	font-style: normal;
	font-size: 1rem;
	display: flex;
	justify-content: flex-end;
	margin-top: 15px;
	align-items: center;

	span {
		margin-right: 5px;
	}
`;

function TablePagination(props) {

	const { pageIndex, pageSize, totalCount, totalPages } = props.data;

	const canPreviousPage = pageIndex !== 0;
	const canNextPage = pageIndex < (totalPages-1);

	return (
		<>
			<Pagination>

				{/* First Page */}
				<PaginationButton
					onClick={() => props.firstPage()}
					disabled={!canPreviousPage}
					small
				>
					<DoubleLeftIcon />
					{canPreviousPage && <Tooltip top>Primeira</Tooltip>}
				</PaginationButton>

				{/* Previous Page */}
				<PaginationButton
					onClick={() => props.previousPage()}
					disabled={!canPreviousPage}
					small
				>
					<LeftIcon />
					{canPreviousPage && <Tooltip top>Anterior</Tooltip>}
				</PaginationButton>

				{/* Next Page */}
				<PaginationButton
					onClick={() => props.nextPage()}
					disabled={!canNextPage}
					small
				>
					<RightIcon />
					{canNextPage && <Tooltip top>Próxima</Tooltip>}
				</PaginationButton>

				<span>
					Página{" "}
					<strong>
						{pageIndex + 1}
					</strong>
				</span>
			</Pagination>
		</>
	);
}

export default TablePagination;
