export const extractParamsFromQuery = (filter) => {
    const argsParams = {};
    let searchFilter = filter.replace('?', '');
    var searchParams = new URLSearchParams(searchFilter);

    for (var [key, value] of searchParams.entries()) {
        argsParams[key] = value;
    }

    return argsParams;
}