import React from "react";
import PropTypes from "prop-types";

import { useTable, usePagination, useSortBy } from "react-table";

import styled from "styled-components";

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Subtitle from "../../Layout/Subtitle/Subtitle";
import { UpIcon, DownIcon } from "../Icon/Icon";

const Styles = styled.div`
	table {
		background: #f9f9f9;
		border-radius: 4px;
		width: 100%;
		border-spacing: 0;
		text-align: left;
		/* font-size: 100px; */
		box-shadow: 2px 2px 2px rgb(211 211 211);

		tr {
			height: 40px;
			border-bottom: 10px solid black;
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		tbody > tr:hover {
			background: #ffffff;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
			cursor: pointer;
		}		

		th,td {
			font-family: Jost;
			font-style: normal;
			padding: 0 1rem;
			margin: 0;
			color: #282828;
			/* max-width: 50px !important; */

			:last-child {
				border-right: 0;
				/* max-width: 50px !important; */
			}

			.number-cell{
				display: block;
				text-align: right;
				width: 100%;
				margin: 0;
			}
		}
	}

		th {
			font-weight: 600;
			font-size: 1rem;
		}

		th:first-child {
			padding: 0 0rem;
		}

		
		table > thead > tr > th:nth-child(1) {
			padding: 0 1rem;
		}

		td {
			border-bottom: 1px solid #28282814;			
			/* max-width: 50px !important; */
			font-weight: 500;
			font-size: 0.8rem;

			button{
				display: flex;
				justify-content: center;
    			width: 100%;
			}
		}

	table tr:last-child {
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		
	}

	
	table tr:last-child:hover td:first-child {
		background: #ffffff;
		border-bottom-left-radius: 15px;
		box-shadow: none;
	}

	table tr:last-child:hover td:last-child {
		background: #ffffff;
		border-bottom-right-radius: 15px;
		box-shadow: none;
	}

	
	table > tbody > tr > td:nth-child(4) {
		${(props) => props.alignDescriptionAtNFe ? `				
				max-width: 19rem;
			` : ''
	}
	}

`;

const Head = styled.th`
	display: flex;
	align-items: flex-end;	
	flex-flow: row nowrap;
`;

const NoDataMsg = styled(Subtitle)`
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const SkeletonTable = styled(Skeleton)`
	width: 20%;
	margin-right: 8px;

	&:first-of-type{
		width: 100%;
	}

	&:last-of-type{
		margin-right: 0;
	}
`;

const defaultPropGetter = () => ({});

function Table({
	columns,
	data,
	getRowProps = defaultPropGetter,
	loading,
	handleRowClick,
	disabledRowClick,
	showAll,
	hideSort,
	alignDescriptionAtNFe,
}) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,

		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: showAll ? data.length : 15,
			},
			loading,
			disabledRowClick,
			showAll,
			hideSort,
		},
		useSortBy,
		usePagination
	);

	return (
		<Styles alignDescriptionAtNFe={Boolean(alignDescriptionAtNFe)}>
			{loading ?
				<>
					{Array(12)
						.fill(1)
						.map(() => (
							<Box sx={{ display: 'flex', marginBottom: "8px" }}>
								<SkeletonTable variant="rectangular" height={40} />
								<SkeletonTable variant="rectangular" height={40} />
								<SkeletonTable variant="rectangular" height={40} />
							</Box>
						))}
				</> :
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th
										{...column.getHeaderProps(
											!hideSort &&
											column.getSortByToggleProps()
										)}
									>
										<Head>
											{column.render("Header")}
											{/* Add a sort direction indicator */}
											<div>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<DownIcon />
													) : (
														<UpIcon />
													)
												) : (
													""
												)}
											</div>
										</Head>
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()}>
						{page.length > 0 ? (
							page.map(
								(row, i) =>
									prepareRow(row) || (
										<tr
											{...row.getRowProps(getRowProps(row))}
											onClick={(e) => {
												if (!disabledRowClick) {
													e.preventDefault();
													handleRowClick &&
														handleRowClick(row);
												}
											}}
										>
											{row.cells.map((cell) => {
												return (
													<td {...cell.getCellProps()}>
														{cell.render("Cell")}
													</td>
												);
											})}
										</tr>
									)
							)
						) : (
							<tr>
								<td colSpan="100%">
									<NoDataMsg>Sem dados</NoDataMsg>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			}
		</Styles >
	);
}

Table.propTypes = {
	handleRowClick: PropTypes.func,
};

export default Table;
