import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "styled-components";
import logo from "../../img/logo.png";
import Button from "../Layout/ButtonDefault/Button";
import Card from "../Layout/Card/Card";
import Form from "../Layout/Form/Form";


const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;

const StyledCard = styled(Card)`
	flex-flow: column;
	padding: 1rem;
	justify-content: center;
	align-items: center;
	width: 400px;
	height: 500px;
	border-radius: 20px;
	z-index: 0;
	overflow: hidden;
	position: relative;
`;

const Img = styled.img`
	height: 30%;
	align-self: center;
	margin-bottom: 15px;
`;

const Title = styled.span`
	font-family: Jost;
	font-style: normal;
	font-weight: 600;
	font-size: 1.2rem;
	line-height: 30px;
	text-align: center;
`;

const Subtitle = styled(Title)`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 0.8rem;
	line-height: 18px;
	text-align: center;
	margin-bottom: 15px;
	color: #888888;
`;

const StyledForm = styled(Form)`
	flex-flow: column;
`;

const ContentButton = styled.div`
	margin-top: 20px;
`;

const StyledButton = styled(Button)`
	height: 40px;
	width: 250px;
	align-self: center;
`;

function LoginPage(props) {
	/*Limpar sessões */
	localStorage.clear()
	sessionStorage.clear();

	const { loginWithRedirect } = useAuth0();

	return (
		<Container>
			<StyledCard>
				<Img src={logo} alt="img" />
				<Title>NF3e Cockpit</Title>

				<StyledForm
					id="loginFront"
					onSubmit={() => loginWithRedirect()}
					flexFlow="column"
				>
					<ContentButton>
						<StyledButton id="entrar" type="submit" variant="contained" textTransform="capitalize">
							Entrar
						</StyledButton>
					</ContentButton>
				</StyledForm>
			</StyledCard>
		</Container>
	);
}

export default LoginPage;
