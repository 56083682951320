import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { nfeAxios } from "../../utils/axiosInstances";
import Header from "../../components/Layout/Header/Header";
import { isAfter } from 'date-fns';
import ReceivedIcon from "../../img/Received.svg";
import EyeIcon from "../../img/Eye-Icon.svg";
import CloseIcon from "../../img/Close-Icon-Black.svg";
import CheckIcon from "../../img/Check-Icon-Black.svg";
import ReloadIcon from "../../img/Reload-Icon-Black.svg";
import DualScreen from "../../img/Dual-Screen-Icon.svg";
import Skeleton from '@mui/material/Skeleton';
import { toast } from "react-toastify";
import ItemSefaz from "../../components/Layout/ItemSefaz/ItemSefaz";

const Container = styled.div`
	width: 100%;
	max-width: 100%;
`
const ContentRow = styled.div`
	width: 100%;
	display: flex;
	margin-top: 32px;
	margin-bottom: 40px;
	gap: 16px;
`

const CardContent = styled.div`
	background: ${props => props.theme.white};
	border-radius: 10px;
	padding: 16px;
	box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
	min-width: 300px;

	&.sfz-card{
		padding: 0;
	}
`

const CardTitle = styled.h3`
	margin: 0;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	display: flex;
	align-items: center;
	color: ${props => props.theme.black};
	margin-bottom: 16px;

	&.second-title{
		margin-bottom: 4px;
	}
`

const CardText = styled.p`
	margin: 0;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	display: flex;
	align-items: center;
	color: ${props => props.theme.black};

	&.subtitle{
		font-weight: 600;
		margin-bottom: 4px;
	}

	&.data-name{
		font-weight: 600;
		margin-top: 16px;
	}

	&.sfz-title{
		font-weight: 600;
		margin-bottom: 0;
	}

	&.bottom-text{
		margin-top: 24px;
		margin-bottom: 0;
	}
`

const TextIndicator = styled.p`
	margin: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: ${props => props.theme.success};
	display: flex;
	align-items: center;

	&.general-title{
		margin-top: 16px;
	}

	&:before{
		content: "";
		margin-right: 8px;
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background: ${props => props.theme.success};
		border: 1px solid #00B106;
	}

	&.warning{
		color: #E8A804;

		&:before{
			background: rgba(232, 168, 4, 0.1);
			border: 1px solid #E8A804;
		}
	}

	&.danger{
		color: #E81204;

		&:before{
			background: rgba(232, 18, 4, 0.1);
			border: 1px solid #E81204;
		}
	}
`

const LineDivider = styled.div`
	width: 100%;
	height: 1px;
	background: rgba(153, 153, 153, 0.3);
	margin: 24px 0px;
`
const CardRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	margin-top: 16px;
`

const ItemMonitor = styled.div`
	background: #FFFFFF;
	box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding: 8px;
	width: 139px;
	height: 116px;
`

const ItemIcon = styled.div`
	background: rgba(4, 57, 232, 0.2);
	border: 1px solid #0439E8;
	border-radius: 4px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;

	&.success{
		background: rgba(0, 187, 7, 0.1);
		border: 1px solid #00B106;
	}

	&.error{
		background: rgba(232, 18, 4, 0.1);
		border: 1px solid #E81204;
	}

	&.alert{
		background: rgba(232, 168, 4, 0.1);
		border: 1px solid #E8A804;
	}

	&.clear{
		background: rgba(4, 177, 232, 0.1);
		border: 1px solid #04B1E8;
	}
`
const ItemData = styled.p`
	margin: 0;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	color: #0439E8;

	&.success{
		color: #00B106;
	}

	&.error{
		color: #E81204;
	}

	&.alert{
		color: #E8A804;
	}

	&.clear{
		color: #04B1E8;
	}
`

const LoadingSkeleton = styled(Skeleton)`
	width: 100%;
	height: 20px;

	&.item-data{
		height: 35px;
	}

	&.sfz-card{
		width: 300px;
		height: 306px;
	}
`


function SaudePage(props) {
	const [loading, setLoading] = useState(true);
	const [health, setHealth] = useState({});
	const [token, setToken] = useState(props.session.token)
	nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

	useEffect(() => {
		getHealth();
	}, []);

	async function getHealth() {
		setLoading(true);
		try {
			const response = await nfeAxios.get(`Nf3eHealthService`);
			if (Boolean(response.data?.expiredCertificateDate)) {
				const dateNow = new Date();
				const dateToCompare = new Date(response.data.expiredCertificateDate);
				const isExpiredCertificate = isAfter(
					dateNow,
					dateToCompare,
				)
				Object.assign(response.data, { isExpiredCertificate })
			}
			setHealth(response.data || {});
		} catch (error) {
			toast.error("Erro ao buscar indicadores de saúde do SaaS.");
			throw error;
		} finally {
			setLoading(false);
		}
	}

	const RenderSefaz = () => health?.healthResponsesByState?.map(item => {
		const { contingency, stateName, healthResponseStatusList } = item;
		const { status, dtCreated } = healthResponseStatusList[0]

		return (
			<ItemSefaz
				stateName={stateName}
				dtCreated={dtCreated}
				status={status}
				contingency={contingency}
				healthResponseStatusList={item.healthResponseStatusList}
			/>
		)
	});

	return (
		<Container>
			<Header
				title="Monitoramento CPFL"
			/>
			<ContentRow>
				<CardContent>
					<CardTitle>Monitoramento Geral</CardTitle>
					<CardText>Indicadores de saúde do SaaS</CardText>
					{loading ? <LoadingSkeleton /> : <>
						{health.saas === 0 && (
							<TextIndicator className="general-title">Em operação</TextIndicator>
						)}
						{health.saas === 1 && (
							<TextIndicator className="warning">Parcialmente em operação</TextIndicator>
						)}
						{health.saas === 2 && (
							<TextIndicator className="danger">Fora de operação</TextIndicator>
						)}</>}
					<LineDivider />
					<CardText className="subtitle">Certificados a vencer</CardText>
					{loading ?
						<LoadingSkeleton />
						:
						<CardText>Próximo vencimento: {" "}
							{new Date(
								health.expiredCertificateDate
							).toLocaleString(["pt-BR"])}
						</CardText>
					}
				</CardContent>
				<CardContent>
					<CardTitle className="second-title">Monitoramento Envio NF3e</CardTitle>
					{loading ? <LoadingSkeleton className="second-title" /> :
						health?.statusNf3e?.lastUpdated && (
							<CardText >
								{`Última atualização: ${health?.statusNf3e?.lastUpdated || Date.now}`}
							</CardText>
						)}
					<CardRow>
						<ItemMonitor>
							<ItemIcon><img src={ReceivedIcon} alt="Item Icon" /></ItemIcon>
							{loading ?
								<LoadingSkeleton className="item-data" />
								:
								<ItemData>
									{health?.statusNf3e
										? `${health?.statusNf3e.invoicesReceived || 0}`
										: 'Não possui'
									}
								</ItemData>
							}
							<CardText className="data-name">Recebidas</CardText>
						</ItemMonitor>
						<ItemMonitor>
							<ItemIcon><img src={EyeIcon} alt="Item Icon" /></ItemIcon>
							{loading ?
								<LoadingSkeleton className="item-data" />
								:
								<ItemData>
									{health?.statusNf3e
										? `${health?.statusNf3e.invoicesConsulted || 0}`
										: 'Não possui'
									}
								</ItemData>
							}
							<CardText className="data-name">Consultadas</CardText>
						</ItemMonitor>
						<ItemMonitor>
							<ItemIcon className="success"><img src={CheckIcon} alt="Item Icon" /></ItemIcon>
							{loading ?
								<LoadingSkeleton className="item-data" />
								:
								<ItemData className="success">
									{health?.statusNf3e
										? `${health?.statusNf3e.invoicesAuthorized || 0}`
										: 'Não possui'
									}
								</ItemData>
							}
							<CardText className="data-name">Aprovadas</CardText>
						</ItemMonitor>
						<ItemMonitor>
							<ItemIcon className="error"><img src={CloseIcon} alt="Item Icon" /></ItemIcon>
							{loading ?
								<LoadingSkeleton className="item-data" />
								:
								<ItemData className="error">
									{health?.statusNf3e
										? `${health?.statusNf3e.invoicesRejected || 0}`
										: 'Não possui'
									}
								</ItemData>
							}
							<CardText className="data-name">Rejeitadas</CardText>
						</ItemMonitor>
						<ItemMonitor>
							<ItemIcon className="alert"><img src={ReloadIcon} alt="Item Icon" /></ItemIcon>
							{loading ?
								<LoadingSkeleton className="item-data" />
								:
								<ItemData className="alert">
									{health?.statusNf3e
										? `${health?.statusNf3e.invoicesInProcess || 0}`
										: 'Não possui'
									}
								</ItemData>
							}
							<CardText className="data-name">Em processamento</CardText>
						</ItemMonitor>
						<ItemMonitor>
							<ItemIcon className="clear"><img src={DualScreen} alt="Item Icon" /></ItemIcon>
							{loading ?
								<LoadingSkeleton className="item-data" />
								:
								<ItemData className="clear">
									{health?.statusNf3e
										? `${health?.statusNf3e.invoicesDuplicated || 0}`
										: 'Não possui'
									}
								</ItemData>
							}
							<CardText className="data-name">Duplicadas</CardText>
						</ItemMonitor>
					</CardRow>
				</CardContent>
			</ContentRow>
			<Header
				title="Monitoramento Sefaz"
			/>
			<ContentRow>
				{loading ? Array(3)
					.fill(1)
					.map(() => (
						<LoadingSkeleton className="sfz-card" height={306} />
					))
					:
					RenderSefaz()
				}
			</ContentRow>
		</Container>
	);
}

export default SaudePage;