import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { nfeAxios } from "../../../../utils/axiosInstances";
import Header from "../../../Layout/Header/Header";
import Card from "../../../Layout/Card/Card";
import FieldSet2 from "../../../Layout/FieldSet/FieldSet2";
import FormGroup2 from "../../../Layout/FormGroup/FormGroup2";
import { Select, InputText } from "../../../Layout/Input/Input";
import Label from "../../../Layout/Label/Label";
import Button from "../../../Layout/ButtonDefault/Button";
import useDynamicForm from "../../../../hooks/useDynamicForm";
import Table from "../../../Layout/Table/Table";
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";

function NoteKey(props) {

    const NewSizeTable = styled.div`
        max-width: 600px;
    `;

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 10px ;
        width: 100%;
    `;

    const DivResult = styled.div`
        display: flex;
        flex-direction: column;
        min-width: 300px;
        min-height: 250px;
    `;

    const CleanFrame = styled.div`
        min-width: 660px;
        max-width: 700px;
        min-height: 150px;
        max-height: 280px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    `;

    const LoadingSpinner = styled(CircularProgress)`
        display: block;
        margin: 20px auto;
    `;

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [showOne, setShowOne] = useState(null);
    const { fields, setFields, handleInputChange } = useDynamicForm();
    const [filterButton, setFilterButton] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
        totalCount: 0,
        totalPages: 0,
    });
    const [reloadPage, setReloadPage] = useState(true);
    const [token, setToken] = useState(props.session.token)
    nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

    useEffect(() => {
        console.log('fields', fields);
    }, [fields])

    async function GetInfoByKey(options = {}) {


        const hasKey = [fields?.key, options?.key].some(item => Boolean(item) === true);
        const hasStatus = [fields?.status, options?.status].some(item => Boolean(item) === true);
        const Params = { params: {} }

        if (!hasKey && !hasStatus) {
            return {} //evita a busca
        }

        if (hasKey) {
            // Object.assign(Params.params, { key: `NF3e${fields?.key || options.key}` })
            Object.assign(Params.params, { key: (fields?.key || options.key) })
        }

        if (hasStatus) {
            Object.assign(Params.params, { status: (fields?.status || options?.status) })
        }
        console.log('Params', Params)

        const response = await nfeAxios.get(`GetInformationNF3e`, Params);
        const { data } = response;

        return data;
    }

    const handleResetButton = () => {
        setFields({
            key: "",
            document: "",
            end: "",
            event: "",
            start: "",
            state: "",
            status: "",
        });

        setFilterButton(!filterButton);
    };

    function firstPage() {
        setPagination({ ...pagination, pageIndex: 0 });
        setReloadPage(true);
    }

    function previousPage() {
        setPagination({ ...pagination, pageIndex: pagination.pageIndex - 1 });
        setReloadPage(true);
    }

    function nextPage() {
        setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 });
        setReloadPage(true);
    }

    const columns = [

        {
            Header: "NF3e",
            accessor: "key",
            Cell: ({ cell }) => {
                const { original } = cell.row;
                return (
                    <NewSizeTable>
                        {original.key}
                    </NewSizeTable>
                );
            },


        }, {
            Header: "Status",
            accessor: "status",
            Cell: ({ cell }) => {
                const { original } = cell.row;
                return (
                    <NewSizeTable>
                        {original.status}
                    </NewSizeTable>

                );
            },
        },

    ];

    const getInfoKey = async () => {
        setLoading(true);
        try {
            const data = await GetInfoByKey();
            setReports(data);
        } catch (error) {
            toast.error("Não foi possível buscar a nota.");
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const Results = (props) => (
        <>
            <NewSizeTable>
                <Table data={props.data} columns={columns} />
            </NewSizeTable>
        </>
    )

    const HandleSearchKey = () => {

        if (!Object.keys(reports).length) {
            return (null)
        }

        if (reports?.message) {
            return (<p>{reports?.message}</p>)
        }

        const check = [reports?.key, reports?.status];
        const hasOptions = check.every((item) => (Boolean(item) === true))

        if (!hasOptions) {
            return (<p>Nada encontrado!</p>)
        }

        // return <Results chave={reports.key} status={reports.status} />
        return <Results data={[reports]} />
    }

    useEffect((item) => {
        const retorno = HandleSearchKey()
        setShowOne(retorno)
    }, [reports])


    return (
        <>
            <CleanFrame>
                <Container>
                    <Header title="Buscar por Chave da Nota" classNameTitle="card-title" />
                    <FieldSet2>

                        <FormGroup2>
                            <InputText
                                id="key"
                                type="text"
                                name="key"
                                size="small"
                                label="Chave da Nota"
                                value={fields.key}
                                onChange={handleInputChange}
                            />
                        </FormGroup2>

                        <FormGroup2 maxW='90' minW='90'>
                            <Button
                                id='filtrar'
                                type="button"
                                onClick={() => getInfoKey()}
                                // onClick={() => ResultsOfSearchKey()}
                                variant="contained"
                                textTransform="capitalize"
                            >
                                Filtrar
                            </Button>
                        </FormGroup2>

                        <FormGroup2 maxW='90' minW='90'>
                            <Button
                                id='limpar'
                                type="button"
                                onClick={handleResetButton}
                                variant="outlined"
                                textTransform="capitalize"
                            >
                                Limpar
                            </Button>
                        </FormGroup2>
                    </FieldSet2>
                    <DivResult>
                        {loading ? <LoadingSpinner /> : showOne}
                    </DivResult>
                </Container>
            </CleanFrame>
        </>
    )

}

export default NoteKey;