import styled from "styled-components";

export default styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* font-size: 20px; */
	/* width: 100%; */
	max-width: 100%;
	max-height: min-content;
	/* justify-content: ${props => props.justifyContent};
	align-items: ${props => props.alignItems};
	flex-direction: row; */
	transition: all .3s;

	&.filter-hidden{
		margin-top: 16px;
		visibility: hidden;
		opacity: 0;
		max-height: 0;
	}

	&.revealed{
		visibility: visible;
		opacity: 1;
		max-height: 200px;
	}

	&.field-end{
		justify-content: flex-end;
		margin-top: 16px;
	}
`;
