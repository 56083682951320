import React, { useState, useEffect } from 'react';
import { Redirect, withRouter, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Sidebar from '../Layout/Sidebar/Sidebar';
import styled from 'styled-components';
import { nfeAxios } from '../../utils/axiosInstances';
import CircularProgress from '@mui/material/CircularProgress';

const Container = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  margin: 0;
`;

const RightPanel = styled.div`
  flex-grow: 1;
  padding: 30px;
  width: calc(100% - 110px);
`;

const ContainerLoad = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled(CircularProgress)`
  margin: 0 auto;
`;

function Layout(props) {
  // console.log('\n• Novo Carregamento')
  const [userData, setUserData] = useState({ name: 'CPFL', initials: 'C' });
  const [sessionAuthenticated, setSessionAuthenticated] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);

  const Auth0 = useAuth0();
  let history = useHistory();

  const CheckIfHasSessionAfterMS = async (props) => {
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function hasUltimateSession() {
      let session = null;
      const maxAttempt = 10;
      for (let i = 0; i < maxAttempt; i++) {
        console.log(`Waiting ${i} seconds...`);

        session = sessionStorage.getItem('session')
        if (Boolean(session)) {
          // console.log('Done - session:', session);
          break;
        }
        await sleep(i * 1000);
      }
      // console.log('Done');
      return Boolean(session);
    }

    const hasSession = await hasUltimateSession();
    // hasSession
    //   ? console.log('tem sessão')
    //   : console.log('NÃO TEM SESSÃO')

    return hasSession
  }

  if (Auth0?.isAuthenticated === true && !sessionAuthenticated) {
    // isto é uma trava para não executar em looping!
    // isso irá disparar um useEffect!
    setSessionAuthenticated(true)
  }

  const saveAndReturnTokenAtSessionStorage = (getTokenFromBackend) => {
    const hasTokenFromBackend = Boolean(getTokenFromBackend?.data?.token);

    if (!hasTokenFromBackend) {
      console.log('Sem token')
      return false;
    }

    const { token } = getTokenFromBackend.data;
    return token;
  }

  const checkAuth = async (Auth0) => {

    if (!Auth0.user) {
      // console.log('sem Auth0.user - checar se há sessão')

      if (!sessionStorage.getItem('session')) {
        // console.log('sem sessão - direcionar pra login - wip')
        return false;
      }

      const currentSession = JSON.parse(sessionStorage.getItem('session'));
      currentSession?.sessionLoading && setSessionLoading(currentSession.sessionLoading);
      currentSession?.user && setUserData(currentSession.user);

      return false;
    }

    // console.log("Auth0.user", Auth0.user);

    // buscar a sessão atual e criar uma sessão nova
    const session = JSON.parse(sessionStorage.getItem('session'))
    const newSession = Object.assign({}, session);

    // checar se o token está válido no backend
    const getTokenFromBackend = await nfeAxios.post(`/GetToken?authToken=${window.API_CODE}`)
    const tokenFromBackend = saveAndReturnTokenAtSessionStorage(getTokenFromBackend)
    console.log('tokenFromBackend', tokenFromBackend)

    // se o token for inválido....
    if (!tokenFromBackend) {
      // ..... redirecionar pra pag de login;
      console.log("sem token do backend!!")
      return false
    }

    // construir objeto user
    const user = Object.assign({}, {})
    Object.assign(user, { name: Auth0.user.name })
    Object.assign(user, { initials: Auth0.user.name[0] })

    // Aplicar informações dentro da nova sessão
    Object.assign(newSession, { "sessionLoading": true })
    Object.assign(newSession, { "token": tokenFromBackend })

    // colocar objeto user dentro da nova sessão
    Object.assign(newSession, { "user": user })

    // adiciona nova sessão no Session Storage
    await sessionStorage.setItem('session', JSON.stringify(newSession))

    /** <!--// Manipulando nova sessão //--> */

    /* modifica o estado SessionLoading como TRUE, cancela: <div>Carregando</div> */
    setSessionLoading(true)

    setUserData(newSession.user)

    // isso deve ser pego do backend
    const userPermissions = ['canSeeNf3eTools', 'hasMocked'];

    const usersCanSeeNf3eTools = ["Gustavo Ramalho de Sousa Ruhena"];
    const hasPermission = usersCanSeeNf3eTools.some((userWithPermission) => (user.name === userWithPermission))

    if (!hasPermission) {
      userPermissions.shift()
    }

    const objToNewSession = {
      name: Auth0.user.name,
      userPermissions: userPermissions,
    }


    await sessionStorage.setItem('newSession', JSON.stringify(objToNewSession));

    console.log('aaaa', await newSession.sessionLoading)

    return Boolean(await newSession.sessionLoading)

  }

  useEffect(() => {
    /* Esta função é a responsável por criar e 
        manipular a sessão ativa após e durante o login.
       esta função é executada após 'sessionAuthenticated'
        receber 'true' na Linha 44.
       Isto é necessário para que não seja executado em looping.
    */
    (async () => {
      await checkAuth(Auth0);
    }
    )();
  }, [sessionAuthenticated])

  useEffect(() => {

    (async () => {
      if (!sessionAuthenticated && !sessionLoading) {
        const hasSession = await CheckIfHasSessionAfterMS();
        if (!hasSession) {
          console.log('redirecionando para /login')
          history.push('/login')
        }
      }

    }
    )();
  }, [sessionLoading])

  return !sessionLoading ? (
    <>
      <ContainerLoad>
        <LoadingSpinner />
      </ContainerLoad>
    </>

  ) : sessionAuthenticated
    || window.REACT_APP_LOGIN_DISABLED === 'true'
    || (async () => await CheckIfHasSessionAfterMS())()
    ? (
      <>
        <Container>
          <Sidebar user={userData} />
          <RightPanel>{props.children}</RightPanel>
        </Container>
      </>
    ) : (
      <>
        {(async () => {
          const hasSession = await CheckIfHasSessionAfterMS();

          if (!hasSession) {
            history.push('/login')
          }

          return (
            <>
              <Container>
                <Sidebar user={userData} />
                <RightPanel>{props.children}</RightPanel>
              </Container>
            </>)

        })()}
        {/* {console.log('redirecionando [ x42586] para /login')}
      <Redirect to="/login" /> */}
      </>
    );
}

export default withRouter(Layout);
