import React, { useCallback, useState } from 'react';
import styled from "styled-components";
import Dialog from '@mui/material/Dialog';
import CloseIconModal from "../../../img/Close-Icon-Modal.svg";
import cn from "clsx";

const CardContent = styled.div`
	background: ${props => props.theme.white};
	border-radius: 10px;
	padding: 16px;
	box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
	min-width: 300px;

	&.sfz-card{
		padding: 0;
	}

    &.modal-card{
		margin-right: 0;
        width: 300px;
        box-sizing: border-box;
	}
`

const CardHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
`

const CardText = styled.p`
	margin: 0;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	display: flex;
	align-items: center;
	color: ${props => props.theme.black};
	margin-bottom: 16px;

	&.subtitle{
		font-weight: 600;
		margin-bottom: 4px;
	}

	&.data-name{
		font-weight: 600;
	}

	&.sfz-title{
		font-weight: 600;
		margin-bottom: 0;
	}

	&.bottom-text{
		margin-top: 24px;
		margin-bottom: 0;
	}
`

const ItemCTA = styled.p`
	margin: 0;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 40px;
	display: flex;
	align-items: center;
	text-decoration-line: underline;
	color: #3D6BFC;
	cursor: pointer;
`

const CardBody = styled.div`
	background: #FFFFFF;
	box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
	border-radius: 0px 0px 10px 10px;
	padding: 16px;
`

const TextIndicator = styled.p`
	margin: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: ${props => props.theme.success};
	display: flex;
	align-items: center;

	&:before{
		content: "";
		margin-right: 8px;
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background: ${props => props.theme.success};
		border: 1px solid #00B106;
	}

	&.warning{
		color: #E8A804;

		&:before{
			background: rgba(232, 168, 4, 0.1);
			border: 1px solid #E8A804;
		}
	}

	&.danger{
		color: #E81204;

		&:before{
			background: rgba(232, 18, 4, 0.1);
			border: 1px solid #E81204;
		}
	}
`

const Modal = styled(Dialog)`
    .MuiPaper-elevation{
        padding: 30px;
        min-width: 1048px;
        max-height: 624px;
    }
`


const LineDivider = styled.div`
	width: 100%;
	height: 1px;
	background: rgba(153, 153, 153, 0.3);
	margin: 24px 0px;
`

const ContentRow = styled.div`
    display: grid;
    grid-template-columns: 300px 300px 300px;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    overflow-y: auto;
    margin-top: 50px;
`
const CloseIcon = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #000000;
`

const ItemSefaz = ({ stateName, dtCreated, status, contingency, healthResponseStatusList }) => {
	const [openModal, setOpenModal] = useState(false);
	const statusDateFormatted = new Date(dtCreated).toLocaleString(
		navigator.language
	);

	const handleOpenModal = useCallback(() => {
		setOpenModal(!openModal)
	}, [openModal]);

	return (
		<>
			<CardContent className="sfz-card">
				<CardHeader>
					<CardText className="sfz-title">Estado: {stateName}</CardText>
					<ItemCTA onClick={handleOpenModal}>Ver histórico</ItemCTA>
				</CardHeader>
				<CardBody>
					<CardText className="data-name">Serviços SEFAZ</CardText>
					<TextIndicator>{status}</TextIndicator>
					<LineDivider />
					<CardText className="data-name">Contingência</CardText>
					<TextIndicator className={cn({ danger: contingency })} >
						{!contingency ? "Não habilitada" : "Habilitada"}
					</TextIndicator>
					<CardText className="bottom-text">Status: {statusDateFormatted}</CardText>
				</CardBody>
			</CardContent>
			<Modal onClose={handleOpenModal} open={openModal}>
				<ModalTitle>Histórico - Estado {stateName}</ModalTitle>
				<CloseIcon src={CloseIconModal} onClick={handleOpenModal} alt="Close Icon" />
				<ContentRow>
					{healthResponseStatusList?.map(healthItem => (
						<CardContent className="modal-card">
							<CardText className="data-name">Serviços SEFAZ</CardText>
							<TextIndicator>{healthItem.status}</TextIndicator>
							<LineDivider />
							<CardText className="data-name">Contingência</CardText>
							<TextIndicator className={cn({ danger: contingency })} >
								{!contingency ? "Não habilitada" : "Habilitada"}
							</TextIndicator>
							<CardText className="bottom-text">Status: {statusDateFormatted}</CardText>
						</CardContent>
					))}
				</ContentRow>
			</Modal>
		</>
	);
}

export default ItemSefaz;