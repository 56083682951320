import React, { useState, useEffect, useContext, useCallback } from "react";
import { nfeAxios } from "../../../utils/axiosInstances";

import { cnpjMask } from "../../../utils/cnpjMask";
import { AuthContext } from "../../../context/AuthContext";
import Header from "../../../components/Layout/Header/Header";
import Table from "../../../components/Layout/Table/Table";
import Button from "../../Layout/ButtonDefault/Button";
import styled from "styled-components";
import { Layout, Upload } from "react-feather";
import Dialog from '@mui/material/Dialog';
import CertificadoDetailsPage from "./CertificadoDetailsPage";
import clsx from "clsx";

function CertificadosListPage(props) {
	const { session } = useContext(AuthContext);
	const [loading, setLoading] = useState([]);
	const [certificados, setCertificados] = useState([]);
	const [settingsFlow, setSettingsFlow] = useState({
		cnpj: null,
		expiresOn: null,
		name: null,
	});
	const [token, setToken] = useState(props.session.token)
	const [openModal, setOpenModal] = useState(false);
	const [isNew, setIsNew] = useState(false);
	nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

	useEffect(() => {
		async function getCertificados() {
			const response = await nfeAxios.get(`Nf3eCertificateAllGet`);
			setCertificados(response.data || []);
			setLoading(false);
		}
		setLoading(true);
		getCertificados();
	}, [session]);

	const handleOpenModal = useCallback((settingsFlow, isNew = false) => {
		setSettingsFlow(settingsFlow);
		setIsNew(isNew);
		setOpenModal(!openModal)
	}, [openModal]);

	const handleRegisterButtonClick = () => {
		props.history.push({
			pathname: `/certificados/${settingsFlow.cnpj || 'new'}`,
			state: { ...settingsFlow },
		});

	};

	const SmallButton = styled(Button)`
		height: 25px;

		svg{
			color: #282828;
		}
	`;

	const StatusBg = styled.span`
		border-radius: 4px;
		background: ${props => props.theme.success};
		border: 1px solid #00B106;
		color: ${props => props.theme.white};
		padding: 2px 4px;
		min-width: 50px;
		display: flex;
		justify-content: center;
		font-weight: 400;

		&.error{
			background: #E81204;
			border: 1px solid #E81204;
		}

		&.warning{
			background: #E8A804;
			border: 1px solid #E8A804;	
		}
	`;

	const BtnHeader = styled.span`
		display: block;
		text-align: center;
		width: 100%;
	`;

	const Modal = styled(Dialog)`
		.MuiPaper-elevation{
			padding: 30px;
			min-width: 700px;
		}
	`

	const columns = [
		{
			Header: "CNPJ",
			accessor: "cnpj",
			width: 100,
			Cell: ({ cell }) => {
				const { original } = cell.row;

				const cnpj = cnpjMask(original.cnpj);

				return cnpj;
			},
		},
		{
			Header: "Certificado",
			accessor: "name",
		},
		{
			Header: <BtnHeader>Status</BtnHeader>,
			accessor: "status",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				const differenceDays = Math.ceil((new Date().getTime() - new Date(original.expiresOn).getTime()) / (1000 * 3600 * 24));
				return <StatusBg
					className={clsx(
						{
							success: differenceDays < 0,
							warning: differenceDays > -7 && differenceDays < 0,
							error: differenceDays > 0
						}
					)}>
					{differenceDays > -7 && differenceDays < 0 ? "Próximo do vencimento" : differenceDays < 0 ? "Válido" : "Vencido"}
				</StatusBg>
			},
		},
		{
			Header: "Válido até",
			accessor: "expiresOn",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return new Date(original.expiresOn).toLocaleString(["pt-BR"]);
			},
		},
		{
			Header: <BtnHeader>Upload do Certificado</BtnHeader>,
			accessor: "id",
			Cell: ({ cell }) => {

				const original = cell.row.original;

				return (
					<SmallButton
						variant="text"
						onClick={() => {
							handleOpenModal(original)
						}}
					>
						<Upload />
					</SmallButton>);
			},
		},
	];

	return (
		<>
			<Header
				title="Certificados"
				showNewRegisterButton
				handleNewRegisterButtonClick={() => handleOpenModal(
					{
						cnpj: null,
						expiresOn: null,
						name: null,
					},
					true
				)}
				icon={Layout}
			/>
			<Modal onClose={handleOpenModal} open={openModal}>
				<CertificadoDetailsPage isNew={isNew} settingsFlow={settingsFlow} />
			</Modal>
			<Table data={certificados} columns={columns} loading={loading} />
		</>
	);
}

export default CertificadosListPage;
