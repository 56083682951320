import { useState, useCallback } from "react";

export default function useDynamicForm(initialState = {}) {
	const [fields, setFields] = useState(initialState);

	const handleInputChange = useCallback((e) => {
		const type = e.target.type;
		const id = e.target.id;
		const value = e.target.value;
		const checked = e.target.checked;
		const name = e.target.name;
		let checkboxValue = "";

		if (checked) {
			checkboxValue = `${fields[id]},${value}`;
		} else if (!checked && type === "checkbox") {
			const list = fields[`${id}`].split(",");

			const filteredList = list.filter((item) => {
				return item !== value;
			});

			checkboxValue = filteredList.join();
		}

		const result =
			type === "number"
				? Number(value.replace(",", "."))
				: type === "checkbox"
					? checkboxValue
					: value;

		id ?
			setFields({ ...fields, [`${id}`]: result })
			:
			setFields({ ...fields, [`${name}`]: result })
	}, [fields]);

	return {
		fields,
		setFields,
		handleInputChange,
	};
}
