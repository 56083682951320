import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import queryString from "query-string";
import clsx from "clsx";

import useDynamicForm from "../../../hooks/useDynamicForm";
import { SelectComponent, InputText } from "../../Layout/Input/Input";
import Button from "../../Layout/ButtonDefault/Button";
import { Divider } from "../../Layout/Divider/Divider";
import FieldSet2 from "../FieldSet/FieldSet2";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { FilterIcon, XIcon, UploadCloudIcon, SearchIcon } from "../Icon/Icon";
import { UF } from "../../../constants/NF3eTools";
import { MoreVertical } from "react-feather";
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 10px 10px ;
	width: 100%;
`;

function Filter(props) {
	const { fields, setFields, handleInputChange } = useDynamicForm();
	const [filterButton, setFilterButton] = useState(false);
	const [showInfoFilter, setShowInfoFilter] = useState(false)
	const firstTime = useRef(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const openActions = Boolean(anchorEl);

	useEffect(() => {
		const filter = props.filter;
		const filterObject = queryString.parse(filter);

		setFields(filterObject);
	}, [props.filter, setFields]);

	useEffect(() => {
		if (firstTime.current) {
			firstTime.current = false;
			return;
		}
		//--
		const query = Object.keys(fields).map((item) => {
			return (fields[item] !== "")
				? `${item}=${fields[item]}`
				: ""
		});
		const filtered = query.filter(item => !!item);
		const queryFiltered = filtered.length ? `?${filtered.join('&')}` : '';
		//--

		props.onChangeFilter && props.onChangeFilter(queryFiltered);
	}, [filterButton]);

	const toggleButtonClick = () => {
		setShowInfoFilter(!showInfoFilter);
	};

	const handleResetButton = () => {
		setFields({
			key: "",
			document: "",
			end: "",
			event: "",
			start: "",
			state: "",
			status: "",
		});

		setFilterButton(!filterButton);
	};

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Container>
			<FieldSet2>
				<InputText
					id="key"
					type="text"
					name="key"
					size="small"
					value={fields.key}
					label="Chave NF3e"
					onChange={handleInputChange}
				/>
				{showInfoFilter === false && (
					<>
						<Divider width="10px" />
						<Button
							type="button"
							onClick={() => setFilterButton(!filterButton)}
							variant="contained"
						>
							<SearchIcon />
						</Button>
					</>
				)}
				<Divider width="4px" />
				<Tooltip title="Abrir filtro" placement="bottom">
					<Button
						type="button"
						variant="contained"
						onClick={() => toggleButtonClick()}
					>
						<FilterIcon />
					</Button>
				</Tooltip>
				<Divider width="4px" />
				<Tooltip title="Ações em massa" placement="bottom">
					<Button
						type="button"
						variant="contained"
						onClick={handleMenuClick}
					>
						<MoreVertical />
					</Button>
				</Tooltip>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={openActions}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<MenuItem onClick={props.handleSendDeleteButton}>Deletar Notas</MenuItem>
					<MenuItem onClick={props.handleButtonClick}>Reenviar Notas</MenuItem>
				</Menu>
			</FieldSet2>
			<FieldSet2 className={clsx({ revealed: showInfoFilter }, "filter-hidden")}>
				<InputText
					id="status"
					type="text"
					name="status"
					size="small"
					label="Status"
					value={fields.status}
					onChange={handleInputChange}
				/>
				<Divider width="5px" />
				<FormControl fullWidth size="small">
					<InputLabel id="state">UF</InputLabel>
					<SelectComponent
						labelId="state"
						id="state"
						label="UF"
						name="state"
						onChange={handleInputChange}
						value={fields.state}
					>
						{UF.map((item) => {
							return <MenuItem value={item.value}>{item.label}</MenuItem>
						})}
					</SelectComponent>
					<Divider width="5px" />
				</FormControl>
				<Divider width="5px" />
				<InputText
					id="event"
					type="text"
					name="event"
					size="small"
					label="Evento"
					value={fields.event}
					onChange={handleInputChange}
				/>
				<Divider width="5px" />
				<InputText
					id="document"
					type="text"
					name="document"
					size="small"
					label="CNPJ"
					value={fields.document}
					onChange={handleInputChange}
				/>
				<Divider width="5px" />
				<InputText
					id="start"
					type="date"
					name="start"
					size="small"
					label="Data Inicial"
					value={fields.start}
					onChange={handleInputChange}
					InputLabelProps={{ shrink: true }}
				/>
				<Divider width="5px" />
				<InputText
					id="end"
					type="date"
					name="end"
					size="small"
					label="Data Final"
					value={fields.end}
					onChange={handleInputChange}
					InputLabelProps={{ shrink: true }}
				/>
				<Divider width="5px" />
				<Button
					type="button"
					onClick={() => setFilterButton(!filterButton)}
					variant="contained"
				>
					<SearchIcon />
				</Button>
			</FieldSet2>
			{showInfoFilter &&
				<FieldSet2 className="field-end">
					<Button
						type="button"
						onClick={handleResetButton}
						cta
						textTransform="capitalize"
					>
						Limpar
					</Button>
				</FieldSet2>
			}
		</Container>
	);
}

export default Filter;
