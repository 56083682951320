import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { nfeAxios } from "../../../../utils/axiosInstances";
import Header from "../../../Layout/Header/Header";
import Label from "../../../Layout/Label/Label";
import Button from "../../../Layout/ButtonDefault/Button";
import Table from "../../../Layout/Table/Table";
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";

function Nf3eSchemaFails(props) {

    const [dataFails, setDataFails] = useState(null)
    const [showFails, setShowFails] = useState()
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(props.session.token)
    nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

    const NewSizeTable = styled.div`
    max-width: 50%;
`;

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 10px ;
        width: 100%;
    `;
    const DivResult = styled.div`
        display: flex;
        flex-direction: column;
        min-width: 300px;
        min-height: 250px;
    `;
    const CleanFrame = styled.div`
        min-width: 660px;
        max-width: 700px;
        min-height: 150px;
        max-height: 280px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    `;
    const ButtonContent = styled.div`
        max-width: 250px;
    `;

    const LoadingSpinner = styled(CircularProgress)`
        display: block;
        margin: 20px auto;
    `;

    async function GetTodayFailedSchemasNF3e(options = {}) {

        const response = await nfeAxios.get(`GetTodayFailedSchemasNF3e`);
        const { data } = response;

        return data;
    }

    const BuscarFalhas = async () => {
        setLoading(true);
        try {
            const retorno = await GetTodayFailedSchemasNF3e();
            setDataFails(retorno);
        } catch (error) {
            toast.error("Não foi possível buscar as notas com falhas.");
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const columns = [
        {
            Header: "Status",
            accessor: "status",
        }, {
            Header: "Quantidade",
            accessor: "quantity",
        },

    ];

    const Results = (props) => (
        <>
            <NewSizeTable>
                <Table data={props.data} columns={columns} />
            </NewSizeTable>
        </>
    )

    // função responsável pela exibição
    const handleShowFails = () => {
        if (!dataFails) return;

        const ErrorMessage = () => (<p> Não foram encontradas notas </p>);

        if (!dataFails.length) {
            return <ErrorMessage />
        }

        return <Results data={dataFails} />
    }

    useEffect(() => {
        const retorno = handleShowFails()
        setShowFails(retorno)
        // loading(false)
    }, [dataFails])


    return (
        <CleanFrame>
            <Container>
                <Header title="Notas com falha no schema" classNameTitle="card-title" />
                <ButtonContent>
                    <Button
                        id='buscar'
                        type="button"
                        onClick={() => BuscarFalhas()}
                        variant="contained"
                        textTransform="capitalize"
                    >
                        Buscar
                    </Button>
                </ButtonContent>
                <DivResult>
                    {loading ? <LoadingSpinner /> : showFails}
                </DivResult>
            </Container>
        </CleanFrame>
    );

}

export default Nf3eSchemaFails;