import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FileText, Download } from "react-feather";

import styled from "styled-components";
import Header from "../../components/Layout/Header/Header";
import Table from "../../components/Layout/Table/Table";
import { nfeAxios } from "../../utils/axiosInstances";
import FieldSet2 from "../Layout/FieldSet/FieldSet2";
import FormGroup2 from "../Layout/FormGroup/FormGroup2";
import { Select, InputText } from "../Layout/Input/Input";
import useDynamicForm from "../../hooks/useDynamicForm";
import Button from "../Layout/ButtonDefault/Button";
import { InfoIcon } from "../Layout/Icon/Icon";
import Modal from "../Layout/Modal/Modal";
import Pagination from "../../components/Layout/Table/Pagination";
import Card from "../Layout/Card/Card";

const StyledCard = styled(Card)`
	z-index: 0;
	overflow: hidden;
	position: relative;		
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color: #f9f9f9;
`;

const SpanScroll = styled.span`
	overflow:auto;
	max-height: 300px;
	width: 100%;

	p:hover {
		border-bottom: 1px dotted #000;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 100%;
`;

function Relatorio(props) {
	const [loading, setLoading] = useState(false);
	const [reports, setReports] = useState([{}]);
	const { fields, setFields, handleInputChange } = useDynamicForm();
	const [filterButton, setFilterButton] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [tableRow, setTableRow] = useState({});
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 15,
		totalCount: 0,
		totalPages: 0,
	});
	const [reloadPage, setReloadPage] = useState(true);
	// const [token, setToken] = useState(props.session.token)
	// nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

	useEffect(() => {
		async function getReports() {

			const Params = { params: {} }
			const argsParams = {};

			if (fields?.start) {
				Object.assign(Params.params, { start: fields.start })
			}
			if (fields?.end) {
				Object.assign(Params.params, { end: fields.end })
			}
			if (fields?.cnpj) {
				Object.assign(Params.params, { cnpj: fields.cnpj })
			}

			Object.assign(Params.params, { PageIndex: pagination.pageIndex })
			Object.assign(Params.params, { Limit: pagination.pageSize })
			Object.assign(Params.params, { ...argsParams })

			const response = await nfeAxios.get(`ReportNf3e`, Params);

			const object = response.data.data;

			if (object) {
				setReports(object || []);
				setPagination(
					{
						pageIndex: response.data.pageIndex,
						pageSize: response.data.pageSize,
						totalCount: response.data.totalCount,
						totalPages: response.data.totalPages,
					} || {}
				);
			}



			setLoading(false);
		}
		reloadPage && getReports();
	}, [reloadPage]);

	async function getReports() {
		setLoading(true);
		const Params = { params: {} }
		const argsParams = {};

		if (fields?.start) {
			Object.assign(Params.params, { start: fields.start })
		}
		if (fields?.end) {
			Object.assign(Params.params, { end: fields.end })
		}
		if (fields?.cnpj) {
			Object.assign(Params.params, { cnpj: fields.cnpj })
		}

		const response = await nfeAxios.get(`ReportNf3e`, {
			params: {
				PageIndex: pagination.pageIndex,
				Limit: pagination.pageSize,
				start: fields.start,
				end: fields.end,
				cnpj: fields.cnpj,
				...argsParams,
			}
		});

		const object = response.data.data;

		if (object) {
			setReports(object || []);
			setPagination(
				{
					pageIndex: response.data.pageIndex,
					pageSize: response.data.pageSize,
					totalCount: response.data.totalCount,
					totalPages: response.data.totalPages,
				} || {}
			);
		}

		setLoading(false);
	}

	async function handleDownloadButtonClick() {
		const Params = { params: {} }
		const argsParams = {};

		if (fields?.start) {
			Object.assign(Params.params, { start: fields.start })
		}
		if (fields?.end) {
			Object.assign(Params.params, { end: fields.end })
		}
		if (fields?.cnpj) {
			Object.assign(Params.params, { cnpj: fields.cnpj })
		}

		setLoading(true);
		const response = await nfeAxios.get(`ReportNf3eToCsv`, {
			params: {
				start: fields.start,
				end: fields.end,
				cnpj: fields.cnpj,
				...argsParams,
			}
		});
		if (response.status === 200) {
			const pom = document.createElement("a");
			pom.setAttribute(
				"href",
				"data:application/octet-stream," +
				encodeURIComponent(response.data)
			);
			pom.setAttribute("download", "data.csv");
			if (document.createEvent) {
				var event = document.createEvent("MouseEvents");
				event.initEvent("click", true, true);
				pom.dispatchEvent(event);
			} else {
				pom.click();
			}

			setLoading(false);
		} else {
			setLoading(false);
			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	function firstPage() {
		setPagination({ ...pagination, pageIndex: 0 });
		setReloadPage(true);
	}

	function previousPage() {
		setPagination({ ...pagination, pageIndex: pagination.pageIndex - 1 });
		setReloadPage(true);
	}

	function nextPage() {
		setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 });
		setReloadPage(true);
	}

	const handleRowClick = (data) => {
		setTableRow(data);
		handleToggleModal();
	}


	const columns = [
		{
			Header: "Data",
			accessor: "date",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					onClick={() => handleRowClick(original)}
				>
					{new Date(original.date).toLocaleString(["pt-BR"])}
				</p>
			},
		},
		{
			Header: "CNPJ",
			accessor: "cnpj",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					onClick={() => handleRowClick(original)}
				>
					{original.cnpj}
				</p>
			},
		},
		{
			Header: <p className="number-cell">Notas aprovadas</p>,
			accessor: "totalApproved",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					className="number-cell"
					onClick={() => handleRowClick(original)}
				>
					{original.totalApproved}
				</p>
			},
		},
		{
			Header: <p className="number-cell">Notas rejeitadas</p>,
			accessor: "totalRejected",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					className="number-cell"
					onClick={() => handleRowClick(original)}
				>
					{original.totalRejected}
				</p>
			},
		},
		{
			Header: <p className="number-cell">Total</p>,
			accessor: "total",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					className="number-cell"
					onClick={() => handleRowClick(original)}
				>
					{original.total}
				</p>
			},
		},
	];

	const handleResetButton = () => {
		setFields({
			key: "",
			document: "",
			end: "",
			event: "",
			start: "",
			state: "",
			status: "",
		});

		setFilterButton(!filterButton);
	};

	const handleToggleModal = () => {
		setShowModal(!false);
	};
	const handleConfirmModalButton = () => {
		setShowModal(false);
	};

	return (
		<Container>
			<Modal
				modalTitle="Mais informações"
				handleConfirmModalButton={handleConfirmModalButton}
				hideFirstButton
				onClose={handleToggleModal}
				open={showModal}
			>
				{(tableRow?.statusList?.length)
					? <SpanScroll>
						{tableRow.statusList.map((item) => {
							return (<p>Status: {item.status || 'N/C'} - Quantidade: {item.quantity}</p>)
						})}
					</SpanScroll>
					: ('Nenhum dado retornado')
				}

			</Modal>
			<Header
				title="Relatório"
				icon={FileText}
			/>
			<StyledCard>
				<FieldSet2>
					<FormGroup2>
						<InputText
							id="start"
							type="date"
							name="start"
							label="Data Inicial"
							value={fields.start}
							InputLabelProps={{ shrink: true }}
							size="small"
							onChange={handleInputChange}
						/>
					</FormGroup2>

					<FormGroup2>
						<InputText
							id="end"
							type="date"
							name="end"
							label="Data Final"
							size="small"
							InputLabelProps={{ shrink: true }}
							value={fields.end}
							onChange={handleInputChange}
						/>
					</FormGroup2>
					<FormGroup2>
						<InputText
							id="cnpj"
							type="text"
							name="cnpj"
							label="CNPJ"
							size="small"
							value={fields.cnpj}
							onChange={handleInputChange}
						/>
					</FormGroup2>

					<FormGroup2 maxW='90' minW='90'>
						<Button
							id='buscar'
							type="button"
							// onClick={() => setFilterButton(!filterButton)}
							onClick={() => getReports()}
							variant="contained"
							textTransform="capitalize"
						>
							Filtrar
						</Button>
					</FormGroup2>

					<FormGroup2 maxW='90' minW='90'>
						<Button
							id='limpar'
							type="button"
							onClick={handleResetButton}
							textTransform="capitalize"
							cta
						>
							Limpar
						</Button>
					</FormGroup2>

				</FieldSet2>
				<Button variant="contained" textTransform="capitalize" startIcon={<Download />} onClick={handleDownloadButtonClick}>Exportar</Button>
			</StyledCard>
			<Table data={reports} columns={columns} loading={loading} />
			<Pagination
				data={pagination}
				firstPage={firstPage}
				previousPage={previousPage}
				nextPage={nextPage}
			/>
		</Container>
	);
}

export default Relatorio;