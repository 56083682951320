import React, { useContext } from "react";

import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Lock, LogOut, Repeat } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthContext } from "../../../context/AuthContext";

const Menu = styled.div`
	background: #27446d;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	margin-bottom: 10px;
	text-align: center;
	vertical-align: middle;
	line-height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-self: center;
	align-items: center;

	:hover {
		cursor: pointer;
		background: ${(props) => props.theme.primaryDark};
	}
`;

const UserDetails = styled.div`
	background: #27446d;
	color: ${(props) => props.theme.secondary};
	height: 60px;
	width: 60px;
	border-radius: 50%;
	line-height: 60px;
	display: flex;
	text-align: center;
	vertical-align: middle;
	flex-direction: row;
	justify-content: center;
	align-self: center;
	align-items: center;
`;

const MenuList = styled.div`
	background: ${(props) => props.theme.secondary};
	visibility: hidden;
	display: flex;
	width: 200px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	transition: 0.3s;
	margin-left: 50px;
	position: absolute;
	bottom: 5px;
`;

const Title = styled.span`
	padding: 5px;
	font-family: Jost;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 30px;
	color: white;
`;

const List = styled.div`
	width: 100%;
	flex-direction: column;
`;

const Item = styled.a`
	background: ${(props) => props.theme.secondary};
	text-align: center;
	vertical-align: middle;
	display: flex;
	flex-direction: row;
	justify-content: ${(props) => props.align};
	align-self: flex-start;
	align-items: center;
	margin: 15px;

	:hover:not(:nth-child(2)) {
		color: ${(props) => props.theme.primary};
		cursor: pointer;
	}
`;

const MenuArrow = styled(MenuList)`
	background: transparent;
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-right: 15px solid white;
	border-bottom: 10px solid transparent;
	margin-left: -10px;
	box-shadow: none;
	bottom: 10px;
`;

const Subtitle = styled.span`
	font-family: Jost;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 10px;
`;

const Label = styled.label`
	margin-left: 20px;
	font-family: Jost;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;

	:hover {
		cursor: pointer;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	z-index: 99;

	&:hover ${MenuList} {
		visibility: visible;
	}
`;

function UserMenu(props) {
	const initials = props.user.initials;
	const name = props.user.name;
	const { handleLogout } = useContext(AuthContext);

	const { logout } = useAuth0();

	function handleButtonLogoutClick() {
		handleLogout();
		logout({ returnTo: `${window.REACT_APP_BASE_REDIRECT_URL}/login` });
	}

	const usernameInitials = sessionStorage.getItem('name')
		? sessionStorage.getItem('name')[0]
		: initials;

	const usernameInitialsSubtitle = sessionStorage.getItem('name') || name;
	
	return (
		<Container>
			<Menu>
				<Title>{usernameInitials}</Title>
			</Menu>
			<MenuList>
				<List>
					<Item align="center">
						<UserDetails>{usernameInitials}</UserDetails>
					</Item>
					<Item align="center">
						<Subtitle>{usernameInitialsSubtitle}</Subtitle>
					</Item>
					<Item onClick={handleButtonLogoutClick}>
						<LogOut />
						<Label>Sair</Label>
					</Item>
					<MenuArrow />
				</List>
			</MenuList>
		</Container>
	);
}

export default withRouter(UserMenu);
