import React, { useState } from "react";

import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Layout, FileText, Monitor, Heart, Tool, ChevronRight } from "react-feather";
import styled from "styled-components";

import logo from "../../../img/evt.svg";
import UserMenu from "../../Layout/UserMenu/UserMenu";
import Tooltip from '@mui/material/Tooltip';
import PermissionGate from "./../../PermissionGate";

const SidebarMenuComponent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: space-between;
	background: rgb(0,44,152);
	background: linear-gradient(176deg, rgba(0,44,152,1) 0%, rgba(61,107,252,1) 100%);
	height: 100vh;
	position: sticky;
	top: 0;
	width: 3%;
	min-width: 80px;
	z-index: 99;
	transition: all .3s;

	&.menuOpen{
		min-width: 200px;
	}
`;

const ToggleMenu = styled.div`
	position: absolute;
	top: 10%;
	right: -20px;
    background: #0834a5;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	border: 1px solid #0a36a8;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all .3s;

	&.menuOpen{
		transform: rotateY(180deg);
	}
`;

const Logo = styled.img`
	position: absolute;
    top: 5%;
	width: 60px;
`;

const Links = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

const Link = styled(NavLink)`
	width: 100%;
	display: flex;
	align-items: center;
    justify-content: center;
	font-size: 1.3rem;
	color: rgba(255, 255, 255, 0.6);
	transition: all .3s;
	text-decoration: none;
	position: relative;
	height: 64px;

	:hover {
		background: white;
		color: #0834a5;
	}

	&.active {
		color: white;

		&:before{
			content: "";
			position: absolute;
			height: 100%;
			width: 3px;
			left: 0;
			top: 0;
			background: ${props => props.theme.white}
		}

		:hover {
			background: white;
			color: #0834a5;
		}
	}

	&.menuOpen{
		justify-content: flex-start;
		padding-left: 16px;
		box-sizing: border-box;
	}
`;
const ItemText = styled.p`
	margin: 0;
	font-size: 16px;
	margin-left: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
    overflow: hidden;
`;

const SidebarMenu = (props) => {
	const pathname = window.location.pathname;
	const path = window.location.pathname.split("/");
	const module = path[1];
	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<SidebarMenuComponent className={clsx({ menuOpen: menuOpen })}>
			<Logo src={logo} />
			<ToggleMenu onClick={() => setMenuOpen(!menuOpen)} className={clsx({ menuOpen: menuOpen })}>
				<ChevronRight />
			</ToggleMenu>
			<Links>
				<>
					<Tooltip title="Monitor NF3e" placement="right">
						<Link
							className={clsx({ menuOpen: menuOpen, active: pathname.includes("/monitor") }, " link")}
							to="/monitor"
						>
							<Monitor />
							{menuOpen && <ItemText>Monitor NF3e</ItemText>}
						</Link>
					</Tooltip>
					<Tooltip title="Relatório" placement="right">
						<Link
							className={clsx({ menuOpen: menuOpen, active: pathname.includes("/relatorio") }, " link")}
							to="/relatorio"
						>
							<FileText />
							{menuOpen && <ItemText>Relatório</ItemText>}
						</Link>
					</Tooltip>
					<Tooltip title="Certificados" placement="right">
						<Link
							className={clsx({ menuOpen: menuOpen, active: pathname.includes("/certificados") }, " link")}
							to="/certificados"
						>
							<Layout />
							{menuOpen && <ItemText>Certificados</ItemText>}
						</Link>
					</Tooltip>
					<Tooltip title="Saúde" placement="right">
						<Link
							className={clsx({ menuOpen: menuOpen, active: pathname.includes("/saude") }, " link")}
							to="/saude"
						>
							<Heart />
							{menuOpen && <ItemText>Saúde</ItemText>}
						</Link>
					</Tooltip>

					{/* <PermissionGate permissions={['canSeeNf3eTools']}>
						<LinkWrapper
							className={clsx({ active: pathname.includes("/nf3etools") })}
						>
							<Link
								className={clsx({ menuOpen: menuOpen, active: pathname.includes("/nf3etools") }, " link")}
								to="/nf3etools"
							>
								<Tool />
								{menuOpen && <ItemText>Nf3eTools</ItemText>}
							</Link>
						</LinkWrapper>
					</PermissionGate> */}
				</>
			</Links>
			<UserMenu user={props.user} module={module} />
		</SidebarMenuComponent >
	);
}

export default SidebarMenu;
