import styled from "styled-components";

export const Divider = (props) => {
    const { width, height, className, children, ...rest } = props;

    const DividerComponent = styled.div`
		min-width: ${props.width || "auto"};
        min-height: ${props.height || "auto"};
	`;

    return (
        <DividerComponent className={className || ""} {...rest}>{children}</DividerComponent>
    );
};

