import styled, { css } from "styled-components";
import Button from '@mui/material/Button';
import clsx from "clsx";

const ButtonDefault = styled(Button)`
	height: 40px;

	&.cta{
		text-decoration: underline;

		&:hover{
			text-decoration: underline;
		}
	}
	
	&.capitalize{
		text-transform: capitalize;
	}

	&.uppercase{
		text-transform: uppercase;
	}

	&.lowercase{
		text-transform: lowercase;
	}

	&.table-btn{
		margin-left: 8px;
		height: 30px;
	}
`;

const ButtonComponent = (props) => {
	const { textTransform, cta, variant, onClick, className, title, ...rest } = props;

	return (
		<ButtonDefault variant={variant} className={clsx({ cta: cta, }, className, textTransform)} onClick={onClick} title={title} {...rest}>
			{props.children}
		</ButtonDefault>
	)
}

export default ButtonComponent;
