export const Colors = {
    primary_one: "#002C98", //blue
    primary_two: "#17387C",
    primary_three: "#20498E",
    primary_four: "#28579F",
    primary_five: "#5B84BF",

    secondary_one: "#29369F",
    secondary_two: "#454CB5",
    secondary_three: "#6062CB",
    secondary_four: "#7B78E1",
    secondary_five: "#968EF8",

    error: "#ED4040",
    informative: "#E99B03",
    success: "#00B106", //green

    text: "#333333", //lightBlack
    subtitle: "#969696", //gray
    disabled: "#D1D1D1",

    white: "#ffffff", //*
    darkGray: "#444444", //*
    mediuDarkGray: "#555555", //*
    lightGray: "#999999", //*
    black: "#000000", //*
};
