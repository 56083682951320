import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { nfeAxios } from "../../../../utils/axiosInstances";
import Header from "../../../Layout/Header/Header";
import Label from "../../../Layout/Label/Label";
import Button from "../../../Layout/Button/Button";
import FormGroup2 from "../../../Layout/FormGroup/FormGroup2";
import useDynamicForm from "../../../../hooks/useDynamicForm";
import FormData from "form-data";
import { toast } from "react-toastify";

function UploadFile(props) {

    const [dataFails, setDataFails] = useState(null)
    const [showFails, setShowFails] = useState()
    const [loading, setLoading] = useState(false);
    const { fields, setFields, handleInputChange } = useDynamicForm();
    const [fileLoading, setFileLoading] = useState(false)

    // const [filename, setFilename] = useState()
    const filenameRef = useRef(null);

    const [settingsFlow, setSettingsFlow] = useState({
        cnpj: null,
        expiresOn: null,
        name: null,
    });

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 10px ;
        width: 100%;
    `;
    const DivResult = styled.div`
        display: flex;
        flex-direction: column;
        min-width: 300px;
        min-height: 250px;
    `;
    const CleanFrame = styled.div`
        min-width: 660px;
        max-width: 700px;
        min-height: 150px;
        max-height: 280px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    `;
    const SmallButton = styled(Button)`
        height: 25px;
      `;
    const LabelFile = styled.input`
        background-color: ${(props) => props.theme.primary};
        border-radius: 10px;
        color: #f9f9f9;
        cursor: pointer;
        margin: 10px 0px;
        padding: 6px 0px;
        text-align: center;
        :hover {
          background: ${(props) => props.theme.primaryDark};
        }
    `;
    const InputFile = styled.input`
        display:none;
    `;
    const InputSubmit = styled.input` 
        background-color: #1976d2;
	    border-radius: 4px;
	    border: 0;
	    display: inline-block;
	    cursor: pointer;
	    line-height: 50%;
	    color: ${(props) => props.theme.secondary};
	    font-family: Jost;
	    font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.75;
	    text-decoration: none;
	    width:78px;
	    height: 40px;
	    margin: 5px 5px 5px 5px;
	    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        transition: all .3s;
	    :hover {
		    background-color: #1565c0;
	    }
    `;

    const fileRef = useRef(null); // cria a referencia para o elemento html

    const toastDownlodId = useRef(null);
    const toastLoadId = useRef(null);

    function downloadTxtFile(name, body) {
        const notify = () => toastDownlodId.current = toast("Fazendo download do arquivo", {
            autoClose: false
        });
        const dismiss = () => toast.dismiss(toastDownlodId.current);

        notify();
        var txtText = body;
        var filename = name;

        var pom = document.createElement("a");
        pom.setAttribute(
            "href",
            "data:application/octet-stream," +
            encodeURIComponent(body)
        );
        pom.setAttribute("download", name + ".csv");
        if (document.createEvent) {
            var event = document.createEvent("MouseEvents");
            event.initEvent("click", true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }
        setTimeout(() => {
            dismiss()
        }, 5000)
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            const files = Object.values(fileRef.current?.files);
            const hasFile = Boolean(files.length);

            // console.log('hasFile',hasFile);
            if (!hasFile) {
                alert('Nenhum arquivo selecionado')
                return false;
            }
            const [file] = files;

            setFileLoading(true)

            const formData = new FormData();
            formData.append("file", file);
            // console.log('name:',file.name)

            const id = toast.loading("Enviando arquivo...")

            // const reQueueInvoices = await
            nfeAxios.post('ReQueueInvoices', formData, {
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            })
                .then(reQueueInvoices => {
                    console.log('res =>', reQueueInvoices)
                    toast.update(id, {
                        render: "Arquivo enviado com sucesso",
                        type: "success",
                        isLoading: false,
                        autoClose: 1000,
                    });
                    setTimeout(() => {
                        downloadTxtFile('file', reQueueInvoices.data);
                    }, 2000)
                }).catch(err => {
                    toast.update(id, {
                        render: "Algo deu errado, tente novamente",
                        type: "error",
                        isLoading: false
                    });
                });

            fileRef.current.value = null; // limpa arquivos do input File
        } catch (error) {
            toast.error("Erro ao enviar arquivo")
            console.error('\n► error', error)
        }
    };

    const Label = styled.label`
        padding: 6px 10px;
        width: 200px;
        background-color: #f9f9f9;
        color: #888888;
        text-align: left;
        display: block;
        margin-top: 10px;
        cursor: pointer;
    `;

    const changeFile = (ev) => {
        if (!Boolean(ev)) {
            return false;
        }
        if (!Boolean(ev.target?.files[0]?.name)) {
            return false;
        }

        const name = ev.target.files[0].name;
        const file = ev.target.files[0];

        // manipula a DOM com ref's
        filenameRef.current.innerText = name;
        fileRef.current.file = file;
    }

    return (
        <CleanFrame>
            <Container>
                <Header title="Reenfileirar Nf3e" classNameTitle="card-title" />
                <FormGroup2>
                    <form onSubmit={onSubmit}>
                        <Label htmlFor={'file'} onClick={async () => {
                            fileRef.current.click()
                        }}>
                            Selecione o arquivo
                        </Label>
                        <InputFile
                            type="file"
                            name="file"
                            ref={fileRef}
                            onChange={changeFile}
                        />
                        <span ref={filenameRef}></span>
                        <InputSubmit type="submit" />
                    </form>
                </FormGroup2>
            </Container>
        </CleanFrame >
    );
}

export default UploadFile;