import {
	Trash,
	Plus,
	Copy,
	Wind,
	ChevronDown,
	ChevronUp,
	ChevronLeft,
	ChevronsLeft,
	ChevronsRight,
	ChevronRight,
	Filter,
	ArrowLeft,
	Info,
	Check,
	X,
	UploadCloud,
	Download,
	RefreshCw,
	Search,
} from "react-feather";
import styled from "styled-components";

const defaultProps = `
		flex-direction: row;
		justify-content: center;
		align-self: center;
		align-items: center;
		width: 100%;
		text-align: center;
	`;

const PlusIcon = styled(Plus)`
	${defaultProps};
`;

const TrashIcon = styled(Trash)`
	${defaultProps};
`;

const CopyIcon = styled(Copy)`
	${defaultProps};
`;

const HairIcon = styled(Wind)`
	${defaultProps};
	transform: rotate(90deg);
`;

const UpIcon = styled(ChevronUp)`
	${defaultProps};
`;

const DownIcon = styled(ChevronDown)`
	${defaultProps};
`;

const LeftIcon = styled(ChevronLeft)`
	${defaultProps};
`;

const RightIcon = styled(ChevronRight)`
	${defaultProps};
`;

const FilterIcon = styled(Filter)`
	${defaultProps};
`;

const ArrowLeftIcon = styled(ArrowLeft)`
	${defaultProps};
`;

const InfoIcon = styled(Info)`
	${defaultProps};
`;

const CheckIcon = styled(Check)`
	${defaultProps};
`;

const XIcon = styled(X)`
	${defaultProps};
`;

const UploadCloudIcon = styled(UploadCloud)`
	${defaultProps};
`;

const DownloadIcon = styled(Download)`
	${defaultProps};
`;

const DoubleLeftIcon = styled(ChevronsLeft)`
	${defaultProps};
`;

const DoubleRightIcon = styled(ChevronsRight)`
	${defaultProps};
`;

const RefreshIcon = styled(RefreshCw)`
	${defaultProps};
`;

const SearchIcon = styled(Search)`
`;

export {
	PlusIcon,
	TrashIcon,
	CopyIcon,
	HairIcon,
	UpIcon,
	DownIcon,
	LeftIcon,
	RightIcon,
	ArrowLeftIcon,
	FilterIcon,
	InfoIcon,
	CheckIcon,
	XIcon,
	UploadCloudIcon,
	DownloadIcon,
	DoubleLeftIcon,
	DoubleRightIcon,
	RefreshIcon,
	SearchIcon,
};
