import styled from "styled-components";

export default styled.div`
	display: flex;
	flex-flow: ${props => props.flexFlow || "row wrap"};
	justify-content: ${props => props.justifyContent};
	background: #ffffff;
	box-shadow: 2px 2px 2px rgb(211 211 211);
	margin: 10px;
	padding: 30px;
	border-radius: 4px;
    margin-left: 0;
	margin-right: 0;
`;
