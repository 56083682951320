import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { toast } from "react-toastify";
import AssetsMapHeader from "../../components/Layout/Header/AssetsMapHeader";
import Table from "../../components/Layout/Table/Table";
import Pagination from "../../components/Layout/Table/Pagination";
import { nfeAxios } from "../../utils/axiosInstances";
import Filter from "../../components/Layout/Filter/Filter";
import Checkbox from "../../components/Layout/Checkbox/Checkbox";
import { DownloadIcon, RefreshIcon, PlusIcon, InfoIcon } from "../Layout/Icon/Icon";
import Button from "../Layout/ButtonDefault/Button";
import Card from "../Layout/Card/Card";
import Modal from "../Layout/Modal/Modal";
import { extractParamsFromQuery } from "../../utils/extractParamsFromQuery";
import clsx from "clsx";

function MonitorNfe(props) {

	const StyledCard = styled(Card)`
		z-index: 0;
		overflow: hidden;
		position: relative;		
		width: 100%;
		justify-content: center;
		align-items: flex-start;
		padding: 10px;
		background-color: #f9f9f9;
	`;

	const Container = styled.div`
		display: flex;
		flex-direction: row;
		width: 100%;
		max-width: 100%;
	`;

	const LeftPanel = styled.div`
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 100%;
		transition: width 0.3s;

		&.opened {
			width: 80%;
		}
	`;

	const Header = styled.div`
		display: flex;
		flex-direction: column;
		flex-basis: 100px;
	`;

	const Title = styled.span`
		font-family: Jost;
		font-weight: 600;
		font-size: 14px;
		color: #282828;
	`;

	const TableButton = styled(Button)`
		height: 25px;

		svg{
			color: #282828;
		}
	`;

	const StatusBg = styled.span`
		border-radius: 4px;
		background: ${props => props.theme.success};
		border: 1px solid #00B106;
		color: ${props => props.theme.white};
		padding: 2px 4px;
		min-width: 50px;
		display: flex;
		justify-content: center;
		font-weight: 400;

		&.error{
			background: #E81204;
			border: 1px solid #E81204;
		}

		&.warning{
			background: #E8A804;
			border: 1px solid #E8A804;	
		}
	`;


	const [loading, setLoading] = useState(false);
	const [nfe, setNfe] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 15,
		totalCount: 0,
		totalPages: 0,
	});

	const [showInfoFilter, setShowInfoFilter] = useState("");
	const [showSendDeleteButton, setShowSendDeleteButton] = useState(false);
	const [filter, setFilter] = useState("");
	const [reloadPage, setReloadPage] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [tableRow, setTableRow] = useState({});
	const [token, setToken] = useState(props.session.token)
	nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

	useEffect(() => {
		async function getMonitorNfe() {
			const { search } = props.history.location
			let argsParams = {};

			if (filter) {
				argsParams = extractParamsFromQuery(filter);
			}

			if (Boolean(search) && !Boolean(filter)) {
				argsParams = extractParamsFromQuery(search);
			}

			const response = await nfeAxios.get('Nf3eMonitor', {
				params: {
					PageIndex: pagination.pageIndex,
					Limit: pagination.pageSize,
					...argsParams,
				}
			});

			console.log(response)

			const object = response.data;

			if (object) {
				setNfe(object.data || []);
				setPagination(
					{
						pageIndex: object.pageIndex,
						pageSize: object.pageSize,
						totalCount: object.totalCount,
						totalPages: object.totalPages,
					} || {}
				);
			}

			setLoading(false);
		}

		setLoading(true);
		reloadPage && getMonitorNfe();
		setReloadPage(false);
	}, [reloadPage, filter]);

	useEffect(() => {
		const findNfe = nfe.find((x) => x.checked);

		if (findNfe) {
			setShowSendDeleteButton(true);
		} else {
			setShowSendDeleteButton(false);
		}
	}, [nfe]);

	const handleButtonClick = (button) => {
		let infoOrFilter = button;

		if (showInfoFilter === button) {
			infoOrFilter = "";
		}

		setShowInfoFilter(infoOrFilter);
	};

	const onChangeFilter = (filtro) => {
		if (filtro !== filter) {
			window.history.replaceState(
				filtro,
				"Monitor NF3e",
				`${props.history.location.pathname}${filtro}`
			);

			setPagination({ ...pagination, pageIndex: 0 });

			setFilter(filtro);
			setReloadPage(true);
		}
	};

	function handleInputChange(e, key, id) {
		const result = nfe.map((item) => {
			if (item.key === key) {
				item[`${id}`] = e.target.checked;
			}
			return item;
		});
		setNfe(result);
	}

	async function handleSendNfe(list) {
		setLoading(true);
		const response = await nfeAxios.post(`Nf3eReenvio`, {
			key: list,
		});

		if (response.status === 200) {
			setLoading(false);
			toast.success("NF3e reenviada com sucesso");
			setReloadPage(true);
		} else {
			setLoading(false);

			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	async function handleDeleteNfe(list) {
		setLoading(true);
		const response = await nfeAxios.post(`Nf3eRemoveFila`, {
			key: list,
		});

		if (response.status === 200) {
			setLoading(false);
			toast.success("NF3e excluída com sucesso");
			setReloadPage(true);
		} else {
			setLoading(false);
			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	function handleSendDeleteButton(type) {
		const result = nfe.filter((item) => item.checked).map((x) => x.key);

		if (type === "send") {
			handleSendNfe(result);
		} else {
			handleDeleteNfe(result);
		}
	}

	function downloadXmlFile(key, body) {
		var xmltext = body;
		var filename = `Nf3e${key}.xml`;
		var pom = document.createElement("a");
		var bb = new Blob([xmltext], { type: "text/plain" });

		pom.setAttribute("href", window.URL.createObjectURL(bb));
		pom.setAttribute("download", filename);

		pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
			":"
		);
		pom.draggable = true;
		pom.classList.add("dragout");

		pom.click();
	}

	async function handleDownloadClick(key) {
		setLoading(true);
		const response = await nfeAxios.post(`Nf3eDownload`, {
			key: [key],
		});

		if (response.status === 200) {
			downloadXmlFile(key, response.data);

			setLoading(false);
			toast.success("NF3e baixada com sucesso");
		} else {
			setLoading(false);
			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	async function handleReprocessClick(key) {
		setLoading(true);
		const response = await nfeAxios.get(`Nf3eReprocessar?key=${key}`);

		if (response.status === 200) {
			setLoading(false);
			toast.success("NF3e reprocessada com sucesso");
		} else {
			setLoading(false);
			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	function firstPage() {
		setPagination({ ...pagination, pageIndex: 0 });
		setReloadPage(true);
	}

	function previousPage() {
		setPagination({ ...pagination, pageIndex: pagination.pageIndex - 1 });
		setReloadPage(true);
	}

	function nextPage() {
		setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 });
		setReloadPage(true);
	}

	const handleRowClick = (data) => {
		setTableRow(data);
		handleToggleModal();
	}

	const columns = [
		{
			Header: "",
			accessor: "check",

			Cell: ({ cell }) => {
				const { original } = cell.row;
				return (
					<Checkbox
						id={"checked_" + original.key}
						value={original.checked}
						onChange={(e) =>
							handleInputChange(e, original.key, "checked")
						}
						checked={original.checked}
					/>
				);
			},
		},
		{
			Header: (
				<Header width={'100px'}>
					<Title>NF3e</Title>
				</Header>
			),
			accessor: "key",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					onClick={() => {
						handleRowClick(original);
					}}
				>
					{original.key}
				</p>
			},
		},
		{
			Header: (
				<Header>
					<Title>Status</Title>
				</Header>
			),
			accessor: "status",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <StatusBg
					onClick={() => {
						handleRowClick(original);
					}}
					className={clsx({ success: original.status === "100", warning: original.status === "105", error: original.status !== "100" && original.status !== "105" })}>
					{original.status}
				</StatusBg>
			},
		},
		{
			Header: (
				<Header>
					<Title>Descrição</Title>
				</Header>
			),
			accessor: "description",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					onClick={() => {
						handleRowClick(original);
					}}
				>
					{original.description}
				</p>
			},
		},

		{
			Header: (
				<Header>
					<Title>UF</Title>
				</Header>
			),
			accessor: "state",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					onClick={() => {
						handleRowClick(original);
					}}
				>
					{original.state}
				</p>
			},
		},

		{
			Header: (
				<Header>
					<Title>CNPJ</Title>
				</Header>
			),
			accessor: "document",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					onClick={() => {
						handleRowClick(original);
					}}
				>
					{original.document}
				</p>
			},
		},
		{
			Header: (
				<Header>
					<Title>Data/Hora</Title>
				</Header>
			),
			accessor: "created",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return <p
					onClick={() => {
						handleRowClick(original);
					}}
				>
					{new Date(original.created).toLocaleString(["pt-BR"])}
				</p>
			},
		},

		{
			Header: "",
			accessor: "download",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return (
					<TableButton
						className="table-btn"
						type="button"
						title="Download NF3e"
						variant="text"
						onClick={() => handleDownloadClick(original.key)}
					>
						<DownloadIcon />
					</TableButton>
				);
			},
		},


	];

	const opened = showInfoFilter !== "" && "opened";

	const handleToggleModal = () => {
		setShowModal(!false);
	};
	const handleConfirmModalButton = () => {
		setShowModal(false);
	};

	return (
		<>

			<Modal
				onClose={handleToggleModal}
				open={showModal}
				modalTitle="Mais informações"
				handleToggleModal={handleToggleModal}
				handleConfirmModalButton={handleConfirmModalButton}
				hideFirstButton
			>
				<p>Lote: {tableRow.lot || 'N/C'}</p>
				<p>Evento: {tableRow.event || 'N/C'}</p>
				<p>Protocolo: {tableRow.protocol || 'N/C'}</p>

			</Modal>
			<AssetsMapHeader
				title="Monitor NF3e"
			/>

			<Container>

				<StyledCard>
					<Filter
						onChangeFilter={onChangeFilter}
						handleCloseButton={() => handleButtonClick("")}
						filter={filter}
						handleSendDeleteButton={handleSendDeleteButton}
						handleButtonClick={handleButtonClick}
					/>
				</StyledCard>

			</Container>

			<Container>
				<LeftPanel>
					<Table data={nfe} columns={columns} loading={loading} alignDescriptionAtNFe />
					<Pagination
						data={pagination}
						firstPage={firstPage}
						previousPage={previousPage}
						nextPage={nextPage}
					/>
				</LeftPanel>
			</Container>
		</>
	);
}


export default MonitorNfe;