import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dialog from '@mui/material/Dialog';

import Button from "../ButtonDefault/Button";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import Table from "../Table/Table";

export default function Modal(props) {
	const DialogComponent = styled(Dialog)`
		.MuiPaper-elevation{
			padding: 24px;
		}
	`;

	const Title = styled.span`
		font-family: Jost;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #282828;
		margin: 10px;
	`;

	const Subtitle = styled.span`
		font-family: Jost;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #888888;
		margin-bottom: 20px;
	`;
	const [linforNfe, setInfoNfe] = useState({});
	const [nfe, setNfe] = useState([]);


	return (
		<DialogComponent onClose={props.onClose} open={props.open}>

			{props.modalTitle && (
				<Title>{props.modalTitle}</Title>
			)}

			{props.modalBody && (
				<Subtitle>{props.modalBody}</Subtitle>
			)}

			{props.children && (
				props.children
			)}

			<ButtonGroup>
				{!props.hideFirstButton && (
					<Button
						type="button"
						onClick={props.handleToggleModal}
					>
						Cancelar
					</Button>
				)}
				{!props.hideSecondButton && (
					<Button
						type="button"
						onClick={props.handleConfirmModalButton}
						color="error"
						fullWidth
						variant="contained"
					>
						Fechar
					</Button>
				)}
			</ButtonGroup>
		</DialogComponent>
	);
}
