import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { cnpjMask } from "../../../utils/cnpjMask";
import { nfeAxios } from "../../../utils/axiosInstances";
import useDynamicForm from "../../../hooks/useDynamicForm";
import Header from "../../../components/Layout/Header/Header";
import Form from "../../../components/Layout/Form/Form";
import Card from "../../../components/Layout/Card/Card";
import FormGroup from "../../../components/Layout/FormGroup/FormGroup";
import Label from "../../../components/Layout/Label/Label";
import { InputText, InputFile } from "../../../components/Layout/Input/Input";
import FieldSet from "../../../components/Layout/FieldSet/FieldSet";
import styled from "styled-components";
import Button from "../../../components/Layout/ButtonDefault/Button";
import ButtonGroup from "../../../components/Layout/ButtonGroup/ButtonGroup";
import Icon from "../../../components/Layout/Icon/Icon";
import { FilePlus } from "react-feather";
import CircularProgress from '@mui/material/CircularProgress';

const LabelFile = styled(Label)`
	background-color: #1976d2;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	margin: 10px 0px;
	padding: 6px 0px;
	text-align: center;
	transition: all 0.3s;
	display: flex;
    align-items: center;
    justify-content: center;

	:hover {
		background-color: #1565c0;
    	box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
	}
`;

const LabelText = styled.p`
	margin: 0;
	margin-left: 16px;
`;

const ButtonDefault = styled(Button)`
	width: 150px;
`;

function CertificadoDetailsPage(props) {
	const { fields, setFields, handleInputChange } = useDynamicForm();
	const [loading, setLoading] = useState(false);
	const isNew = props.isNew;
	const [settingsFlow, setSettingsFlow] = useState({
		cnpj: null,
		expiresOn: null,
		name: null,
	});


	useEffect(() => {
		setSettingsFlow(Object.assign({ settingsFlow }, { ...props.settingsFlow }));
	}, []);

	const createCertificado = async () => {
		try {

			const createCert = async (fields) => {
				const res = Object.assign({ ...fields });

				const response = await nfeAxios.post(
					`Nf3eCertificateImport`,
					res
				);
				return response;

			};
			const updateCert = async (fields) => {

				const res = Object.assign({ ...fields }, { ...settingsFlow });
				delete res.settingsFlow;

				const response = await nfeAxios.post(
					`Nf3eCertificateUpdate`,
					res
				);
				return response;
			}

			const response = isNew ? await createCert(fields) : await updateCert(fields);
			const status = response.status || {};
			setLoading(false);

			if (status === 200) {
				toast.success("Certificado criado com sucesso");
				props.history.push("/certificados");
			} else {
				toast.error("Não foi possível enviar o certificado");
			}
		} catch (err) {
			setLoading(false);
			toast.error("Não foi possível enviar o certificado");
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		createCertificado();
	};

	function bytesToSize(bytes) {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes === 0) return "0 Byte";
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
	}

	const handleFileInput = (e) => {
		var f = e.target.files[0]; // FileList object
		var reader = new FileReader();
		// Closure to capture the file information.
		reader.onload = (function (theFile) {
			return function (e) {
				var binaryData = e.target.result;
				//Converting Binary Data to base 64
				var base64String = window.btoa(binaryData);
				//showing file converted to base64
				setFields({
					...fields,
					certificate: base64String,
					file: { name: f.name, size: f.size },
				});
			};
		})(f);
		// Read in the image file as a data URL.
		reader.readAsBinaryString(f);
	};

	return (
		<>
			<Header title="Cadastro de Certificado" classNameTitle="card-title" />
			{isNew && props.settingsFlow.name}
			<Form onSubmit={handleFormSubmit} flexFlow="row wrap">
				<FieldSet>
					<FormGroup>
						<LabelFile htmlFor="certificate">
							<FilePlus />
							<LabelText>
								Escolher arquivo...
							</LabelText>
						</LabelFile>
						<InputFile
							type="file"
							name="certificate"
							id="certificate"
							onChange={handleFileInput}
						/>
					</FormGroup>
				</FieldSet>
				{fields.certificate && (
					<FieldSet>
						<FormGroup>
							<Label>
								Arquivo: {fields.file.name} -{" "}
								{bytesToSize(fields.file.size)}
							</Label>
						</FormGroup>
					</FieldSet>
				)}

				<FieldSet>
					<FormGroup>
						<InputText
							type="text"
							id="name"
							label="Nome"
							size="small"
							defaultValue={fields.name || props.settingsFlow.name}
							onChange={handleInputChange}
							disabled={!isNew}
						/>
					</FormGroup>
					<FormGroup>
						<InputText
							type="password"
							id="Password"
							label="Senha"
							size="small"
							defaultValue={fields.Password}
							onChange={handleInputChange}
						// disabled={!isNew}								
						/>
					</FormGroup>
				</FieldSet>
				<FieldSet>
					<FormGroup>
						<InputText
							type="text"
							id="cnpj"
							label="CNPJ"
							size="small"
							defaultValue={
								fields.cnpj ? cnpjMask(fields.cnpj) : props.settingsFlow.cnpj
							}
							onChange={handleInputChange}
							disabled={!isNew}
						/>
					</FormGroup>
				</FieldSet>
				<FieldSet justifyContent="center">
					<ButtonGroup>
						<ButtonDefault type="submit" variant="contained">
							{loading ? <CircularProgress size="20px" color="text" /> : "Salvar"}
						</ButtonDefault>
					</ButtonGroup>
				</FieldSet>
			</Form>
		</>
	);
}

export default CertificadoDetailsPage;
